import i18next from 'i18next';
import { Fragment, PropsWithChildren, useEffect, useState } from 'react';
import { initReactI18next } from 'react-i18next';
import SPFullLogoLoader from '../../components/SPFullLogoLoader';
import SPLoadingScreen from '../../components/SPLoadingScreen';

import * as config from './config/i18n';

import translationLoader from './translation-loader';

async function initializeI18n() {
    try {
        await i18next
            .use(translationLoader)
            .use(initReactI18next)
            .init({
                fallbackLng: config.fallback,
                ns: config.namespaces,
                defaultNS: config.defaultNamespace,
                interpolation: {
                    escapeValue: false,
                },
            });

        return true;
    } catch {
        return false;
    }
}

export const capitalizeFirstLetter = (input: string) => {
    return input.charAt(0).toUpperCase() + input.slice(1);
};

export function InternationalizationProvider({ children }: PropsWithChildren<{}>) {
    const [initialised, setInitialised] = useState(false);

    useEffect(() => {
        initializeI18n().then(() => setInitialised(true));
    }, []);

    if (initialised) {
        return <Fragment>{children}</Fragment>;
    }

    return (
        <SPLoadingScreen>
            <SPFullLogoLoader />
        </SPLoadingScreen>
    );
}

export const trans = i18next.t;

export const languageMapper: Record<string, string> = {
    nb: 'no',
    en: 'en',
};
