import { Actions } from '../actions/types';
import { SPCentralOrganization } from '@styreportalen/common';

export interface OrganizationsProps {
    organizations?: SPCentralOrganization[];
}

const initialState: OrganizationsProps = {};

function organizationsReducer(
    state: OrganizationsProps = initialState,
    action: Partial<{ type: Actions; data: any }>,
): OrganizationsProps {
    switch (action.type) {
        case Actions.SET_ORGANIZATIONS:
            return {
                ...state,
                organizations: action.data,
            };
        default:
            return state;
    }
}

export default organizationsReducer;
