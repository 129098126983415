import { css } from '@emotion/react';
import { SetStateAction, useMemo, useState } from 'react';
import { Alert, Button, Card, Form } from 'react-bootstrap';
import i18next from 'i18next';
import { FAuth } from '../../services/firebase/firebase';

export default function ForgotScreen({ setForgotPass }: { setForgotPass: (value: SetStateAction<boolean>) => void }) {
    const defaultSettings = {
        sending: false,
        email: '',
        succMessage: '',
        failMessage: '',
    };
    const [emailProps, setEmailProps] = useState<{
        sending: boolean;
        email: string;
        succMessage: string;
        failMessage: string;
    }>(defaultSettings);

    const isStyreportalen = useMemo(() => {
        return window.location.hostname === process.env.sentral_hostname;
    }, []);

    const portalUrl = useMemo(() => {
        return isStyreportalen ? process.env.portal_url : process.env.tutti_portal_url;
    }, [isStyreportalen]);

    const click = async () => {
        setEmailProps({ ...emailProps, sending: true });
        await FAuth()
            .sendPasswordResetEmail(emailProps.email)
            .then(() => {
                setEmailProps({
                    ...emailProps,
                    sending: false,
                    failMessage: '',
                    succMessage: 'Glemt-passord e-post sendt!',
                });
                setTimeout(() => {
                    setForgotPass(false);
                }, 1000);
            })
            .catch((err) => {
                setEmailProps({ ...emailProps, sending: false, succMessage: '', failMessage: (err as Error).message });
            });
    };

    return (
        <div
            css={css`
                display: flex;
                justify-content: center;
                margin-top: 1rem;
            `}>
            <Card
                css={css`
                    max-width: 60ch;
                    width: 100%;
                `}>
                <Card.Header className="logoBox">
                    <img
                        src={`${portalUrl}/img/${isStyreportalen ? 'styreportalen' : 'tutti'}.png`}
                        className="img-fluid px-4 py-3"
                        alt={`${isStyreportalen ? 'Styreportalen' : 'Tutti'} logo`}
                    />
                </Card.Header>
                <Card.Body>
                    {emailProps.succMessage && <Alert variant="success">{emailProps.succMessage}</Alert>}

                    {emailProps.failMessage && <Alert variant="danger">{emailProps.failMessage}</Alert>}

                    <p className="text-center text-uppercase">Glemt passord</p>

                    <Form.Group>
                        <Form.Label>{i18next.t('login:email_address')}</Form.Label>
                        <Form.Control
                            autoComplete="email"
                            placeholder={i18next.t('login:email_address')}
                            disabled={emailProps.sending}
                            type="email"
                            value={emailProps.email}
                            onChange={({ target: { value } }) => setEmailProps({ ...emailProps, email: value })}
                        />
                    </Form.Group>

                    <div className="d-flex justify-content-between mt-3">
                        <Button variant="primary" disabled={!emailProps.email || emailProps.sending} onClick={click}>
                            Få nytt passord {emailProps.sending && <em className="fas fa-circle-notch fa-spin" />}
                        </Button>
                        <Button variant="link" onClick={() => setForgotPass(false)}>
                            Gå til innlogging
                        </Button>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}
