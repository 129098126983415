import { useEffect, useState } from 'react';
import { GlobalUserDetails } from '@styreportalen/common';
import { FFirestore } from '../../services/firebase/firebase';
import { Nullable } from '../../forms/fields/supplementary/Membertable/util';

const loadZoho = async (appId: string | undefined, oid: string | undefined, enabled?: boolean) => {
    let script = document.getElementById('zoho-asap') as HTMLScriptElement;
    if (enabled && !script) {
        const head = document.querySelector('head');

        script = document.createElement('script');
        script.src = `https://desk.zoho.eu/portal/api/web/asapApp/${appId}?orgId=${oid}`;
        script.id = 'zoho-asap';
        script.defer = true;
        const nonce = process.env?.ZOHO_NONCE;
        if (nonce) {
            script.nonce = nonce;
        }

        head?.appendChild(script);
    }

    return script;
};

type ZohoSettings = { app: string; id: string };
export function useInitializeZoho(user: Nullable<GlobalUserDetails>, sentralId: string) {
    const [zohoSettings, setZohoSettings] = useState<ZohoSettings>();
    const haveAccess = !!user?.core_access?.admin || !!user?.federations?.[sentralId];
    useEffect(() => {
        if (!haveAccess || !sentralId) {
            return;
        }
        FFirestore()
            .collection('sentral')
            .doc(sentralId)
            .collection('settings')
            .doc('zoho')
            .get()
            .then((docSS) => {
                setZohoSettings(docSS.data() as ZohoSettings);
            });
    }, [haveAccess, sentralId]);

    useEffect(() => {
        if (!haveAccess || !zohoSettings?.id || !zohoSettings?.app) {
            return;
        }

        loadZoho(zohoSettings.app, zohoSettings?.id, true).then((script) => {
            if (script) {
                (window as any).ZohoDeskAsapReady = function (callback: any) {
                    const asyncCalls = ((window as any).ZohoDeskAsap__asyncalls =
                        (window as any).ZohoDeskAsap__asyncalls || []);

                    if ((window as any).ZohoDeskAsapReadyStatus) {
                        if (callback) asyncCalls.push(callback);
                        asyncCalls.forEach(function (fn: any) {
                            if (fn) fn();
                        });
                        (window as any).ZohoDeskAsap__asyncalls = null;
                    } else if (callback) {
                        asyncCalls.push(callback);
                    }
                };
            }
        });
    }, [zohoSettings?.app, zohoSettings?.id, haveAccess]);
}
