const no = {
    // USAGE: i18next.t([key, fallback_key (default)])

    deviation: {
        NMF: {
            common: {
                org_id: 'Korpsnummer',
                org_name: 'Korpsnavn',
                org_region: 'Korpsregion',
            },

            pages: {
                home: 'Korpssøk',
            },
            organizations: {
                columnHeaders: {
                    org_id: 'Korpsnummer',
                    org_name: 'Korpsnavn',
                    org_region: 'Korpsregion',
                },
            },

            organization: {
                title: 'Korps',
                headers: {
                    label: {
                        members: 'Musikanter og drillere',
                    },
                },

                tabs: {
                    organization: 'Korpsinfo',
                    agreements: 'Tilleggsavtaler',
                },

                org_name: 'Korpsnavn',

                send_email: {
                    labels: {
                        org_email: 'Korps e-post',
                        org_invoice_email: 'Korps fakturaepost',
                    },
                },
            },
        },
        natteravn: {
            common: {
                org_id: 'Gruppe ID',
                org_name: 'Gruppenavn',
                org_region: 'Region',
            },

            pages: {
                home: 'Natteravngrupper',
                'files-shared': 'Offentlig delte filer',
                'files-private': 'Filer',
            },
            organizations: {
                columnHeaders: {
                    org_id: 'ID',
                    org_name: 'Gruppenavn',
                    org_region: 'Region',
                },
            },

            organization: {
                headers: {
                    label: {
                        members: 'Medlemmer',
                    },
                },

                tabs: {
                    organization: 'Natteravngruppeinfo',
                    agreements: 'Avtaler',
                },

                org_name: 'Gruppenavn',

                send_email: {
                    labels: {
                        org_email: 'Gruppens e-post',
                        org_invoice_email: 'Gruppens faktura-epost',
                    },
                },
            },
        },
        korforbundet: {
            organization: {
                title: 'Kor',
            },
        },
        default: {
            pages: {
                home: 'Organisasjoner',
            },
            organizations: {
                columnHeaders: {
                    org_id: 'ID',
                    org_name: 'Organisasjonsnavn',
                },
            },

            organization: {
                headers: {
                    label: {
                        members: 'Medlemmer',
                    },
                },

                tabs: {
                    organization: 'Organisasjonsinfo',
                    agreements: 'Avtaler',
                },

                org_name: 'Organisasjonsnavn',

                send_email: {
                    labels: {
                        org_email: 'Organisasjonens e-post',
                        org_invoice_email: 'Organisasjonens fakturaepost',
                    },
                },
            },
        },
    },
    access: {
        accesses: 'Tilganger',
        no_access: 'Ingen tilgang',
        have_access: 'Har tilgang',
        portal: 'Portalen',
        member_app: 'Tutti',
        asset_app: 'Eiendeler',
        all: 'Alle',
        last_signed_in: 'Sist logget inn',
        last_updated: 'Sist hentet statuser',
    },
    languages: {
        NO: 'Norsk',
        EN: 'Engelsk',
    },
    common: {
        from: 'Fra',
        to: 'Til',
        label: 'Felt',
        description: 'Beskrivelse',
        hint: 'Hint',
        information: 'Informasjon',
        open_link: 'Åpne lenke',
        copy_link: 'Kopier lenke',
        copy: 'Kopier',
        this_lang: 'Norsk',
        language: 'Språk',
        lists: 'Lists',
        access: 'Tilgang',
        country: 'Land',
        log_out: 'Logg av',
        comment: 'Kommentar',
        refetch: 'Hent på nytt',
        never: 'Aldri',
        save: 'Lagre',
        are_you_sure: 'Er du sikker på at du vil avbryte?',
        loading: 'Laster',
        update: 'Oppdater',
        delete: 'Slett',
        none_selected: 'Ingen valgt',
        cancel: 'Avbryt',
        back: 'Tilbake',
        select: 'Velg',
        submit: 'Send inn',
        app_access: 'App Tilgang',
        you_dont_have_access:
            'Du har ikkje tilgang til denne appen.\nKontakt Styreportalen AS på support@styreportalen.no',
        search: 'Søk',
        search_by: 'Søk med',
        edit: 'Rediger',
        members: 'Medlemmer',
        parents: 'Foresatte',
        reply: 'Svar',
        children: 'Barn',
        force: 'Tving',
        choose: 'Velg',
        send: 'Send',
        yes: 'Ja',
        no: 'Nei',
        kommunenr: 'Kommunenummer',
        title: 'Tittel',
        upload: 'Last opp',
        name: 'Navn',
        region: 'Region',
        you_dont_have_notes: 'Du har ikke tilgang til noter i dette prosjektet.',
        not_found: 'Fant ikke {item}en!',
        organization: 'Organisasjon',
        show: 'Vis',
        hide: 'Skjul',
        read_past_tense: 'Lest',
        compose: 'Opprett',
        missing: 'Mangler',
        of: 'Av',
    },
    login: {
        login: 'Logg inn',
        sign_in: 'Logg inn',
        email_address: 'E-postadresse',
        password: 'Passord',
        forgot_password: 'Glemt passord',
    },
    organizations: {
        columnHeaders: {
            org_id: 'Korpsnummer',
            org_nr: 'Organisasjonsnummer',
            org_name: 'Korpsnavn',
            org_region: 'Korpsregion',
            founding_date: 'Stiftet',
            mainStatus: 'Hovedstatus',
            status: 'Status',
            bank_account: 'Bankkonto',
            'address.address': 'Adresse',
            'address.city': 'Sted',
            'address.municipality_name': 'Kommunenavn',
            'address.municipality_number': 'Kommunenummer',
            'address.county': 'Fylke',
            'address.post_code': 'Postnummer',
            'contact.email': 'E-post',
            'contact.invoice_email': 'Faktura e-post',
            'contact.phone': 'Mobil',
            'custom_fields.Drilltype': 'Drilltype',
            'custom_fields.Besetning': 'Besetning',
            'custom_fields.Korpstype': 'Type',
            region: 'Region',
            members: 'Medlemmer',
            has_portal: 'Har portal',
        },
        default_background_url:
            'https://firebasestorage.googleapis.com/v0/b/styreportalen-drift.appspot.com/o/Div%2FNMF%2FTopp_Portal-min.png?alt=media&token=1a64bd4a-7fbf-4d6a-9bd7-ef94588872e9',
        default_profile_url:
            'https://firebasestorage.googleapis.com/v0/b/styreportalen-drift.appspot.com/o/Div%2FNMF%2Fdemoikon_nmf.png?alt=media&token=b7686443-67a7-417e-9246-592a465c68c7',
        default_slogan: 'Alle snakker korps',
        tabs: {
            homepage: 'Startside',
            board: 'Styret',
            organization: 'Korpsinfo',
            members: 'Medlemmer',
            internal_notes: 'Interne notater',
            agreements: 'Tilleggsavtaler',
        },
        previous_board_members: 'tidligere styremedlemmer',
        active_board: 'Aktivt styre',
        membership_statuses: {
            A: 'Aktiv',
            O: 'Opphørt',
        },
        members: {
            columnHeaders: {
                personId: 'Medlemsnummer',
                fullName: 'Navn',
                membershipStatus: 'Status (medlemskap)',
                membershipType: 'Medlemskapstype',
                primaryEmail: 'E-post',
                secondaryEmail: 'Sekundær e-post',
                mobile: 'Mobil',
                phone: 'Telefon',
                city: 'Sted',
                group: 'Gruppe',
                enrolled_date: 'Registreringsdato',
                instrument: 'Instrument',
                insurance: 'Forsikring',
                postCode: 'Postnummer',
                primaryParentName: 'Primærforesattes navn',
                secondaryParentName: 'Sekundærforesattes navn',
            },
            category: {
                1: 'Musikant',
                2: 'Aspirant',
                3: 'Driller',
                4: 'Styret/verv',
                5: 'Dirigent/instruktør',
                6: 'Drillaspirant',
                7: 'Ikke-utøvende NMF-medlem',
                9: 'Ikke NMF-medlem',
            },
            insurance: {
                M: 'Med instrument',
                U: 'Uten instrument',
                I: 'Ingen',
            },
        },
    },
    file_archive: {
        title: 'Fil arkiv',
        shared_title: 'Delte filer',
        shared_description: 'Filer er synlig for {org}',
        private_title: 'Lukkede filer',
        private_description: 'Filer kun synlig i sentral',
        loading_message: 'laster',
        processing_message: 'Utfører endringer',
        upload: 'Last opp',
        upload_files: 'Last opp filer',
        create_folder: 'Opprett mappe',
        rename_file: 'Endre navn på fil',
        rename_folder: 'Endre navn på mappe',
        delete_file: 'Slett fil',
        delete_files: 'Slett filer',
        delete_folder: 'Slett mappe',
        delete_folders: 'Slett mapper',
        download: 'Last ned',
        selectedForUpload: 'filer valgt for opplasting',
        filename: 'Filnavn',
        filepath: 'Filsti',
        chosen_folder: 'Valgt mappe',
        upload_button: 'Last opp filer',
        upload_prompt: {
            title: 'Er du sikker på du vil laste opp filer her?',
            text: 'Dette vil laste opp valgt fil til filarkivet.',
        },
        delete_prompt: {
            title: 'Slett?',
            delete_files: 'Du er i ferd med å slette alle filene valgt! \nVennligst bekreft at du ønsker dette.',
            delete_file: 'Du er i ferd med å slette valgt fil! \nVennligst bekreft at du ønsker dette.',
            delete_folders:
                'Du er i ferd med å slette flere mapper og alle filene de inneholder! \nVennligst bekreft at du ønsker dette.',
            delete_folder:
                'Du er i ferd med å slette en mappe og alle filene den inneholder! \nVennligst bekreft at du ønsker dette.',
        },
        control_prompt: {
            title: 'Duplikat filnavn',
            text: 'Overskriv eller lag kopi?',
            input_text: 'Gjør for alle',
            confirm_text: 'Overskriv',
            deny_text: 'Lag kopi',
        },
        upload_modal: {
            title: 'Last opp filer til',
            drop_zone: 'Dra og slipp mapper eller filer her eller klikk på feltet for å velge filer!',
            default_alert:
                'OBS! Veldig store eller dype mappestrukturer kan medføre at nettleseren din krasjer.\nVennligst del opp opplastningen i mindre biter hvis dette er tilfelle!',
            error_alert: 'Noe gikk galt under opplastningen av',
            upload_alert: 'Laster opp fil',
            view_button: 'Se filer',
            reselect_button: 'Velg filer på nytt',
        },
    },
    firebase_error: {
        'auth/internal-error': 'Feil i systemet, prøv igjen.',
        'auth/invalid-phone-number': 'Feil i telefonnummeret, prøv igjen.',
        'auth/invalid-email': 'Feil i epostaddressen, epostadressen er ugyldig.',
        'auth/user-not-found': 'Brukeren eksisterer ikke.',
        'auth/wrong-password': 'Feil passord.',
        'auth/invalid-verification-code': 'Feil sms kode, prøv igjen!',
        'login/invalid-input': 'Feil! Ugyldig input.',
    },
    forms: {
        form_fields: {
            info: 'Skjema',
            help: 'Hjelpetekster',
            fields: 'Felter',
            action_button_text: 'Knapp text',
            field_names: {
                create_limit_date: 'Skjema innsending frist',
                create_limit_date_from: 'Åpnes',
                create_limit_date_to: 'Lukkes',
                create_limit_date_description: 'Sett når skjema åpner og når frist for skjema er',
                help_overViewHelp: 'Hjelpetekst oversikt',
                help_listInfo: 'Hjelpetekst rapport',
                help_easyFillOut: 'Hjelpetekst hurtig',
                submissionHelp: 'Hjelpetekst innsending',
                title: 'Skjema navn',
            },
        },
        adult_education: {
            adult_education_code: 'Fagkode',
            start_date_range: 'Fra dato',
            end_date_range: 'Til dato',
            activity: 'Aktivitet',
            created: 'Opprettet',
            day_count: 'Antall dager',
            exam: 'Eksamen',
            finished_date: 'Fullført dato',
            hours_with_instructor: 'Antall timer med lærer/instruktør',
            instructor_name: 'Instruktør',
            priority_funds: 'Prioritet midler',
            report_finished_date: 'Rapport fullført dato',
            settled_amount: 'Avregnet beløp',
            special_allowance: 'Særtilskudd',
            status_label: 'Status',
            time_of_day_label: 'Tidspunkt',
            status: {
                NOT_FILLED: 'Ikke Startet',
                PROCESSING: 'Under arbeid',
                FILLED: 'Utfylt',
                SENT: 'Sendt',
                APPROVED: 'Godkjent',
                REJECTED: 'Avvist',
                CONTROL: 'Kontroll',
            },
            time_of_day: {
                BOTH: 'Begge',
                DAY: 'Dag',
                NIGHT: 'Natt',
            },
        },
    },
    pages: {
        invoices: 'Faktura',
        events: 'Aktiviteter',
        about: 'Om denne appen',
        feedback: 'Gi tilbakemelding',
        compose_feed: 'Opprett Feed',
        projects: 'Prosjekter',
        profile: 'Bruker',
        select_organization: 'Velg Organisasjon',
        messages: 'Meldinger',
        parents: 'Foresatte',
        notifications: 'Varsler',
        qr: 'QR',
        settings: 'Innstillinger',
        // In use
        home: 'Korpssøk',
        members: 'Medlemmer',
        calendar: 'Kalender',
        access: 'Tilganger',
        messagecenter: 'Meldingssenter',
        newsletter: 'Nyhetsbrev',
        setup: 'Oppsett',
        regions: 'Regioner',
        users: 'Brukere',
        additionalAgreements: 'Tilleggsavtaler',
        applicationcenter: 'Søknadssenter',
        adult_education: 'Voksenopplæring',
        mail_accounts: 'E-post',
        support: 'Support',
        files: 'Filarkiv',
        'files-private': 'Filer',
        'files-shared': 'Delte filer',
    },
    profile: {
        membership: {
            category: {
                musician: 'Musikant',
                aspirant: 'Aspirant',
                drill: 'Driller',
                board: 'Styret/verv',
                conductor_instructor: 'Dirigent/instruktør',
                drillaspirant: 'Drillaspirant',
                non_performing_nmf_member: 'Ikke-uktøvende NMF medlem',
                not_nmf_member: 'Ikke NMF medlem',
            },
            'membership-fields': {
                forsikring: 'Forsikring',
                contingent: 'Kontingentsats',
            },
            insurance: {
                u: 'Uten instrument',
                m: 'Med instrument',
                i: 'Ingen forsikring',
            },
            status: {
                active: 'Aktivt',
                ended: 'Opphørt',
            },
            department: 'Avdeling',
            group: 'Gruppe',
            secondary_group: 'Sekundærgruppe',
            instrument_id: 'Instrument',
            member_category: 'Medlemskategori',
            membership_id: 'Medlemsnummer',
            person_id: 'Person-ID',
            enrolled_date: 'Registreringsdato',
            membership: 'Medlemskap',
        },
        age: 'Alder',
        verv_name: 'Verv',
        other_fields: 'Annet',
        org_defined_fields: 'Egendefinerte felter',
        children: 'Barn',
        parents: 'Foresatte',
        group: 'Gruppe',
        department: 'Avdeling',
        secondary_group: 'Sekundærgruppe',
        instrument_group: 'Instrumentgruppe',
        saving: 'Lagrer',
        select_department: 'Velg avdeling',
        select_group: 'Velg gruppe',
        select_group_2: 'Velg sekundærgruppe',
        label_none: 'Ingen',
        full_name: 'Navn',
        first_name: 'Fornavn',
        last_name: 'Etternavn',
        birth_date: 'Fødselsdato',
        gender: 'Kjønn',
        status: 'Status',
        active: 'Aktiv',
        inactive: 'Inaktiv',
        address: 'Adresse',
        address_2: 'Adresse 2',
        post_code: 'Postkode',
        city: 'Poststed',
        place: 'Sted',
        personalia: 'Personalia',
        contact_info: 'Kontakt',
        phone: 'Telefon',
        mobile: 'Mobil',
        email: 'E-post',
        email_2: 'E-post 2',
        birthday: 'Fødselsdato',
        genders: {
            i: 'Ukjent',
            k: 'Kvinne',
            m: 'Mann',
        },
        statuses: {
            '0': 'Aktiv',
            '1': 'Midlertidig',
            '2': 'Permisjon',
            '3': 'Inaktiv',
            '4': 'Foresatte',
        },
    },
    support: {
        support: 'Support',
        title: 'Tittel',
        description: 'Beskrivelse',

        error: 'Noe gikk galt:',
        missing_fields: 'Det mangler informasjon i ett eller flere felter!',
        inquiry_received: 'Vi har mottatt din henvendelse. Vi kontakter deg innen kort tid.',
        create_inquiry: 'Opprett supporthenvendelse',
        read_documentation: 'Dokumentasjon for Styreportalen:',
    },
    utility: {
        CREATED: 'Opprettet',
        SHOW_MORE: 'Vis flere',
        SAVE_BUTTON: 'Lagre',
        CANCEL_BUTTON: 'Avbryt',
        CLOSE_BUTTON: 'Lukk',
        REQUIRED: 'Påkrevd',
        SENT: 'Sendt',
        SEND_INQUIRY: 'Send henvendelse',
        NONE_SELECTED: 'Ingen valgt',
    },
    components: {
        members: {
            COLUMN_HEADERS: {
                ADDRESS: 'Adresse',
                ADDRESS_2: 'Adresse 2',
                AGE: 'Alder',
                BIRTHDAY: 'Fødselsdato',
                CHILDREN: 'Barn',
                CITY: 'Sted',
                CONTINGENT: 'Kontingent',
                DATE: 'Registreringsdato',
                DEPARTMENT: 'Avdeling',
                EMAIL: 'E-post',
                EMAIL_2: 'E-post 2',
                ENDED_DATE: 'Sluttdato',
                FAMILIES: 'Familier',
                FIRST_NAME: 'Fornavn',
                GROUP: 'Gruppe',
                INSTRUMENT: 'Instrument',
                INSURANCE: 'Forsikring',
                LAST_NAME: 'Etternavn',
                MEMBER_CATEGORY: 'Medlemskategori',
                MEMBERSHIP_TYPE: 'Medlemskapstype',
                MOBILE: 'Mobil',
                PARENT_1_EMAIL: 'E-post (foresatt 1)',
                PARENT_1_MOBILE: 'Mobil (foresatt 1)',
                PARENT_1_NAME: 'Navn (foresatt 1)',
                PARENT_2_EMAIL: 'E-post (foresatt 2)',
                PARENT_2_MOBILE: 'Mobil (foresatt 2)',
                PARENT_2_NAME: 'Navn (foresatt 2)',
                PERSON_ID: 'Medlemsnummer',
                PERSON_STATUS: 'Status',
                POST_CODE: 'Postnummer',
                SECONDARY_GROUP: 'Sekundærgruppe',
                TELEPHONE: 'Telefon',
            },
            CUSTOM_FIELDS: 'Egendefinerte felter',
            FIRST_NAME: 'Fornavn',
            GENDER_OPTIONS: {
                FEMALE: 'Kvinne',
                MALE: 'Mann',
            },
            QUERY_VALUE: 'Fritekst',
            MEMBER: 'Medlem',
            MEMBER_CATEGORY: {
                aspirant: 'Aspirant',
                board: 'Styret/verv',
                drill: 'Driller',
                drill_aspirant: 'Drillaspirant',
                instructor: 'Dirigent/instruktør',
                musician: 'Musikant',
                non_executive_NMF_member: 'Ikke-utøvende NMF-medlem',
                non_NMF_member: 'Ikke NMF-medlem',
                other: 'Andre',
            },
            MEMBERS: 'Medlemmer',
            OTHERS: 'Andre personer',
            PARENT: 'Foresatt',
            PARENTS: 'Foresatte',
            PERSON: 'Person',
            PERSON_STATUS: {
                ACTIVE: 'Aktiv',
                ENDED: 'Sluttet',
                RETIRED: 'Permisjon',
                TEMPORARY: 'Midlertidig',
                UNKNOWN: 'Ukjent',
            },
            PERSONS: 'Personer',
            REQUIRED_FIELDS: 'Påkrevde felter',
            TABLE_PROPERTIES: {
                CHECKED_BUTTON: {
                    active_members: 'Vis kun aktive medlemmer',
                    active_persons: 'Vis kun aktive personer',
                    curr_and_previous_parents: 'Vis tidligere og nåværende foresatte',
                    curr_parents: 'Vis kun nåværende foresatte',
                    inactive_members: 'Vis også sluttede medlemmer',
                    inactive_persons: 'Vis også sluttede personer',
                },
                CUSTOM_FIELDS_BUTTON: {
                    column_headers: {
                        field: 'Felt',
                        type: 'Type',
                        values: 'Verdier',
                    },
                    delete: 'Slett',
                    fieldtype_options: {
                        long_text: 'Lang tekst',
                        predefined_tag: 'Tagg (forhåndsdefinert)',
                        single_choice: 'Enkeltvalg',
                        tag: 'Tagg',
                        text: 'Tekst',
                    },
                    header: 'Administrer egendefinerte felter',
                    header_2: 'Administrer egendefinert felt',
                    inputs: {
                        fieldname: 'Feltnavn',
                        fieldtype: 'Felttype',
                        description: 'Beskrivelse',
                        values: 'Verdier (tomme felter vil slettes når du lagrer)',
                    },
                    no_custom_fields: 'Det eksisterer ingen egendefinerte felter enda',
                    save: 'Lagre',

                    sweetalert: {
                        text: 'Er du sikker på at vil fjerne dette feltet?',
                    },
                },
                FILEEXPORT_BUTTON: {
                    header: 'Velg eksport format',
                    tooltip_title: 'Fileksport',
                },
                members: 'Medlemmer',
                Members_document_name: 'Medlemsliste',
                others: 'Andre Personer',
                Others_document_name: 'Andre_personer_liste',
                parents: 'Foresatte',
                Parents_document_name: 'Foresatteliste',
                status_active: 'Aktiv',
                status_previous: 'Tidligere',
            },
        },
        organization_home: {
            open_in_portal: 'Åpne i styreportalen',
            open_in_tutti_portal: 'Åpne i portal',
        },
    },
};

export default no;
