import { FAuth, FFunctions } from '../firebase/firebase';
import firebase from 'firebase/app';

const auth = FAuth();
const functions = FFunctions();

export const updateLastLogin = async (): Promise<firebase.functions.HttpsCallableResult> =>
    functions.httpsCallable('updateLastLogin')();

export async function login(email: string, password: string) {
    if (email === '' || password === '') {
        throw new Error('login/invalid-input');
    }

    if (email === 'tokenlogin') {
        return auth.signInWithCustomToken(password);
    } else {
        return auth.signInWithEmailAndPassword(email, password);
    }
}
