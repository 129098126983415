import styled from '@emotion/styled';

const SPLoadingScreen = styled.div`
    display: flex;
    background-color: white;
    flex: 1;
    align-items: center;
    justify-content: center;
    height: 100vh;
`;

export default SPLoadingScreen;
