import firebase from 'firebase/app';
import { firebase as firebaseInstance } from '@styreportalen/common';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

const config = {
    // apiKey: 'AIzaSyBijRVRZxwozK-uEWlwkBNkEIHaoD1ISww',
    // authDomain: 'styreportalen-drift.firebaseapp.com',
    // databaseURL: 'https://styreportalen-drift.firebaseio.com',
    // projectId: 'styreportalen-drift',
    // storageBucket: 'styreportalen-drift.appspot.com',
    // messagingSenderId: '781727237877',
    // appId: '1:781727237877:web:77e790067517a12b3ae7bc',
    // measurementId: 'G-XR4N7W2G2T',
    apiKey: process.env.apiKey,
    authDomain: process.env.authDomain,
    databaseURL: process.env.databaseURL,
    projectId: process.env.projectId,
    storageBucket: process.env.storageBucket,
    messagingSenderId: process.env.messagingSenderId,
    appId: process.env.appId,
    measurementId: process.env.measurementId,
};

export const firebaseApp = firebaseInstance.initializeApp(config);

export const FFirestore = firebase.firestore;
export const FStorage = firebase.storage;
export const FAuth = firebase.auth;
export const FFunctions = (() => firebaseApp.functions('europe-west3')) as typeof firebase.functions;

const hasEmulator = process.env.useEmulator;
if (hasEmulator === "true") firebaseApp.functions("europe-west3")['useEmulator']('localhost', 5001);
