import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FC, useState } from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';
import { AlwaysRouterItem, NavigationVisibility } from '../../AppRouter';

const DropdownButton = styled.button<{ isActive: boolean }>`
    border-left: 2px solid transparent;
    font-size: 0.875rem;

    padding: 12px 24px;

    text-align: left;
    text-decoration: none;

    border: 0;
    border-radius: 0;
    border-left: 2px solid ${({ isActive }) => (isActive ? 'var(--bs-primary)' : 'transparent')};

    background-color: ${({ isActive }) => (isActive ? '#fcfcfc' : 'transparent')};
    color: ${({ isActive }) => (isActive ? 'var(--bs-primary)' : 'var(--bs-black)')};

    &:hover,
    &:focus {
        color: var(--bs-primary);
        box-shadow: none;
    }
`;

const StyledLink = ({ isActive, deep, ...props }: LinkProps & { isActive: boolean; deep?: boolean }) => {
    return (
        <Link
            {...props}
            css={css`
                display: flex;

                padding: ${deep ? '10px 20px 10px 53px' : '12px 24px'};
                text-decoration: none;

                color: ${isActive ? 'var(--bs-primary)' : 'var(--bs-black)'};

                border-left: 2px solid ${isActive ? 'var(--bs-primary)' : 'transparent'};
                background-color: ${isActive ? '#fcfcfc' : 'transparent'};

                &:focus,
                &:hover {
                    color: var(--bs-primary);
                }
            `}
        />
    );
};

const Icon = styled.i`
    margin-right: 8px;
`;

const LinkLabel = styled.span`
    line-height: 17px;
`;

const SidebarButtonDropdown: FC<{ routerItem: AlwaysRouterItem }> = ({ routerItem }) => {
    const location = useLocation();
    const routerItemActivePath = routerItem.exactPathMatch
        ? location.pathname === routerItem.path
        : location.pathname.startsWith(routerItem.path);

    const [open, setOpen] = useState(routerItemActivePath);

    if (!routerItem.items) {
        return null;
    }

    const items = routerItem.items.filter((item) => item.visibility === NavigationVisibility.ALWAYS);

    if (items.length === 0) {
        return (
            <StyledLink isActive={routerItemActivePath} to={routerItem.path}>
                <Icon className={routerItem.icon} />
                <LinkLabel>{routerItem.label}</LinkLabel>
            </StyledLink>
        );
    }

    return (
        <div className="d-flex flex-column">
            <DropdownButton isActive={routerItemActivePath} onClick={() => setOpen(!open)}>
                <Icon className={routerItem.icon} />
                <LinkLabel>{routerItem.label}</LinkLabel>
            </DropdownButton>
            {open &&
                routerItem.items.map((subRouterItem, index) => {
                    if (subRouterItem.visibility === NavigationVisibility.ALWAYS) {
                        const subRouterItemActivePath = subRouterItem.exactPathMatch
                            ? location.pathname === subRouterItem.path
                            : location.pathname.startsWith(subRouterItem.path);

                        return (
                            <StyledLink
                                isActive={subRouterItemActivePath}
                                deep
                                to={subRouterItem.path}
                                key={`subRouterItem-${subRouterItem.path}-${index}`}>
                                <LinkLabel>{subRouterItem.label}</LinkLabel>
                            </StyledLink>
                        );
                    }

                    return null;
                })}
        </div>
    );
};

export default SidebarButtonDropdown;
