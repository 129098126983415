import { useEffect, useState } from 'react';
import { FFirestore } from '../services/firebase/firebase';
import { useFederations } from '../contexts/SPUserContext';
import { PublicSentralInfo } from '@styreportalen/common';

export const useSentralsPublicInfo = () => {
    const [publicInfo, setPublicInfo] = useState<Record<string, PublicSentralInfo>>();
    const federations = useFederations();

    useEffect(() => {
        (async () => {
            const sentralInfoQuery = await FFirestore().collection('shared/sentralinfo/public_sentral_info').get();

            setPublicInfo(
                sentralInfoQuery.docs.reduce((output, infoSS) => {
                    if (federations[infoSS.id]) {
                        return {
                            ...(output || {}),
                            [infoSS.id]: {
                                id: infoSS.id,
                                ref: infoSS.ref,
                                ...(infoSS.data() as PublicSentralInfo),
                            },
                        };
                    }

                    return output;
                }, {} as Record<string, PublicSentralInfo>),
            );
        })();
    }, [federations]);

    return publicInfo;
};
