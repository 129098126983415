import moment from 'moment';
import React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { SPUserContextProvider } from './contexts/SPUserContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import AppRouter from './navigation/AppRouter';
import store from './redux/store';
import './services/firebase/firebase';
import { InternationalizationProvider } from './services/i18n';

import './styles/index.scss';
import 'moment/locale/nb';
import SPLogoIcon from './resources/images/SP_logo_icon.svg';
import SPLogoName from './resources/images/SP_logo_text.svg';
import TuttiLogoName from './resources/images/Tutti_color_text.svg';
import TuttiLogoIcon from './resources/images/Tutti_color_logo.svg';

moment.locale('nb');
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnMount: false,
            refetchOnWindowFocus: false,
        },
    },
});
function App() {
    return (
        <React.StrictMode>
            <Provider store={store}>
                <QueryClientProvider client={queryClient}>
                    <InternationalizationProvider>
                        <SPUserContextProvider>
                            <AppRouter />
                        </SPUserContextProvider>
                    </InternationalizationProvider>
                </QueryClientProvider>
            </Provider>
        </React.StrictMode>
    );
}

ReactDOM.render(<App />, document.getElementById('root'));

export { SPLogoName, SPLogoIcon, TuttiLogoName, TuttiLogoIcon };
