import { FC, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Card, Col, Row } from 'react-bootstrap';
import { useSentralsPublicInfo } from '../../hooks/useSentralsPublicInfo';
import SPLoader from '../../components/SPLoader';

interface SelectFederationProps {}

const SelectFederation: FC<SelectFederationProps> = (props) => {
    const sentralInfo = useSentralsPublicInfo();
    const history = useHistory();

    useEffect(() => {
        if (!sentralInfo) {
            return;
        }
        if (Object.keys(sentralInfo).length === 1) {
            history.replace(`/${Object.keys(sentralInfo)[0]}/`);
        }
    }, [sentralInfo, history]);

    if (!sentralInfo) {
        return <SPLoader />;
    }

    return (
        <div className="wrapper">
            <section
                className="select-section-container"
                style={{
                    marginTop: 0,
                }}>
                <div className="content-wrapper">
                    <h3 className="text-center text-thin mt-3 mb-2">Velg forbund</h3>
                    <Row className="d-flex justify-content-center">
                        <Col md={6}>
                            <Card
                                className="w-100"
                                style={{
                                    marginTop: '45px',
                                }}>
                                <div
                                    className="d-flex flex-column"
                                    style={{
                                        padding: '1rem 2rem 1rem 2rem',
                                        border: '1px solid black',
                                    }}>
                                    {Object.entries(sentralInfo).map(([key, info]) => (
                                        <Link
                                            key={key}
                                            className="text-body text-decoration-none"
                                            style={{
                                                margin: '1rem 0 1rem 0',
                                                letterSpacing: '0.025em',
                                                padding: '0.8rem',
                                                textTransform: 'uppercase',
                                                boxShadow:
                                                    '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
                                            }}
                                            to={`/${key}/`}>
                                            <div key={key}>
                                                <img
                                                    className="img-thumbnail rounded-circle"
                                                    src={info.images.profile_path || ''}
                                                    alt=""
                                                    style={{ height: '40px', width: '40px', marginRight: '5px' }}
                                                />
                                                <span>{key}</span>
                                            </div>
                                        </Link>
                                    ))}
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </section>
        </div>
    );
};

export default SelectFederation;
