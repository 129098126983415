import { UserSentral } from '@styreportalen/common';
import { createContext, Dispatch, FC, SetStateAction, useContext, useEffect, useState } from 'react';
import { WithDocumentId } from '../typings/declarations';
import { FederationsContext } from './SPUserContext';
import { FFirestore } from '../services/firebase/firebase';
import { ISentralSettings } from '@styreportalen/common/lib/sentral/settings/sentral-settings';

export const SelectedFederationContext = createContext<{
    selectedFederation: string | undefined;
    setSelectedFederation: Dispatch<SetStateAction<string>>;
}>({
    selectedFederation: undefined,
    setSelectedFederation: () => {},
});

interface CurrentFederationContextProps {
    selectedId: string;
}

export const CurrentFederationContext: FC<CurrentFederationContextProps> = (props) => {
    const [selectedFederation, setSelectedFederation] = useState<string>(props.selectedId);
    return (
        <SelectedFederationContext.Provider
            value={{
                selectedFederation: selectedFederation,
                setSelectedFederation: setSelectedFederation,
            }}>
            {props.children}
        </SelectedFederationContext.Provider>
    );
};

export const useCurrentUserFederation = (): WithDocumentId<UserSentral> | undefined => {
    const federations = useContext(FederationsContext);
    const selectedFederation = useContext(SelectedFederationContext);

    if (!selectedFederation.selectedFederation) {
        return undefined;
    }
    const federation = federations?.[selectedFederation.selectedFederation];
    if (!federation) {
        return undefined;
    }

    return { ...federation, document_id: selectedFederation.selectedFederation };
};
export const useCurrentFederation = (): WithDocumentId<{ name: string }> | undefined => {
    const selectedFederation = useContext(SelectedFederationContext);
    const [federation, setFederation] = useState<{ name: string }>();
    useEffect(() => {
        if (!selectedFederation.selectedFederation) {
            return;
        }
        FFirestore()
            .collection('sentral')
            .doc(selectedFederation.selectedFederation)
            .get()
            .then((docSS) => {
                setFederation(docSS.data() as { name: string } | undefined);
            });
    }, [selectedFederation.selectedFederation]);

    if (!federation || !selectedFederation.selectedFederation) {
        return undefined;
    }
    return { ...federation, document_id: selectedFederation.selectedFederation };
};
export const useCurrentFederationID = (): string | undefined => {
    const selectedFederation = useContext(SelectedFederationContext);

    return selectedFederation.selectedFederation;
};

export const useFederationSettings = () => {
    const [settings, setSettings] = useState<ISentralSettings | null>();
    const fedId = useCurrentFederationID();

    useEffect(() => {
        if (!fedId) {
            return;
        }
        FFirestore()
            .collection('sentral')
            .doc(fedId)
            .collection('settings')
            .doc('data')
            .get()
            .then((ss) => {
                setSettings(ss.data() || null);
            });
    }, [fedId]);

    return settings;
};
