import styled from '@emotion/styled';
import { useUser } from '../contexts/SPUserContext';
import { AlwaysRouterItem, NavigationVisibility, RouterItems } from './AppRouter';
import SidebarButtonDropdown from './sidebar/sidebarLargeButton/SidebarButtonDropdown';
import { useCurrentFederationID, useCurrentUserFederation } from '../contexts/CurrentFederationContext';

const SidebarContainer = styled.div`
    display: flex;
    flex-direction: column;

    background-color: white;

    border-right-width: 1px;
    border-right-color: rgb(204, 204, 204);
    border-right-style: solid;
`;

const SidebarHeader = styled.p`
    font-size: 13;
    color: #919da8;
    padding: 15px 12px;
    margin-bottom: 0;
`;

const Sidebar = () => {
    const user = useUser();
    const federation = useCurrentUserFederation();
    const fedId = useCurrentFederationID();
    const MenuOptions = RouterItems(fedId).filter(
        (item) =>
            (item.visibility === NavigationVisibility.ALWAYS ||
                (item.visibility === NavigationVisibility.CONDITIONAL &&
                    item.condition &&
                    item.condition(item.path, user, federation))) &&
            (!item.adminOnly || user.core_access.admin),
    ) as AlwaysRouterItem[];

    return (
        <SidebarContainer>
            <SidebarHeader>Meny</SidebarHeader>

            {MenuOptions.map((menuOption, index) => (
                <SidebarButtonDropdown routerItem={menuOption} key={`menuOptions-large-${index}`} />
            ))}
        </SidebarContainer>
    );
};

export default Sidebar;
