import { FunctionComponent, memo, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { SPLogoName, SPLogoIcon, TuttiLogoName, TuttiLogoIcon } from '../index';

type ComponentLoaderProps = {
    animate?: boolean;
};
export const LogoSVG = styled('object')<ComponentLoaderProps>`
    @keyframes rotateDrum {
        0% {
            transform: rotate(0deg);
            scale: 1;
        }
        50% {
            transform: rotate(360deg);
            scale: 0.8;
        }
        100% {
            transform: rotate(360deg);
            scale: 1;
        }
    }

    max-height: 5rem;
    width: 100%;
    height: 100%;
    animation: all ease 1s;
    ${({ animate }) => animate && 'animation: rotateDrum 1s linear infinite;'}
`;
export const TextSVG = styled('object')`
    max-height: 5rem;
    height: 100%;
    width: 100%;
    min-height: 100px;
    animation: all ease 1s;
`;

const LogoContainer = styled('div')`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100vh;
    max-height: 100%;
    width: 100vw;
    max-width: 100%;
    animation: all ease 1s;
`;

const SPFullLogoLoader: FunctionComponent<ComponentLoaderProps> = ({ animate = true }) => {
    const isStyreportalen = useMemo(() => {
        return window.location.hostname === process.env.sentral_hostname;
    }, []);

    console.log(isStyreportalen);

    return (
        <LogoContainer>
            <span style={{ width: '5rem' }}>
                <LogoSVG type="image/svg+xml" animate={animate} data={isStyreportalen ? SPLogoIcon : TuttiLogoIcon} />
            </span>
            <span>
                <TextSVG type="image/svg+xml" data={isStyreportalen ? SPLogoName : TuttiLogoName} />
            </span>
        </LogoContainer>
    );
};

export default memo(SPFullLogoLoader);
