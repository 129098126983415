import { combineReducers, createStore } from 'redux';
import organizationsReducer, { OrganizationsProps } from './reducers/organizationsReducer';

export interface SPReducerState {
    organizationsReducer: OrganizationsProps;
}

const rootReducer = combineReducers({
    organizationsReducer: organizationsReducer,
});

const configureStore = () => createStore(rootReducer);
const store = configureStore();

export default store;
