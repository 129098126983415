import { GlobalUserDetails, UserSentral } from '@styreportalen/common';
import { createContext, useContext, useEffect, useState } from 'react';
import LoginScreen from '../screens/login/LoginScreen';
import { updateLastLogin } from '../services/auth';
import { FAuth, FFirestore } from '../services/firebase/firebase';
import { WithDocumentReferences } from '../typings/declarations';
import SPFullLogoLoader from '../components/SPFullLogoLoader';
import SPLoadingScreen from '../components/SPLoadingScreen';
import firebase from 'firebase/app';

export const UserContext = createContext<WithDocumentReferences<GlobalUserDetails> | null>(null);
export const AuthUserContext = createContext<firebase.User | null>(null);
export const FederationsContext = createContext<Record<string, UserSentral> | null>(null);

export const SPUserContextProvider: React.FC = ({ children }) => {
    const [authUser, setAuthUser] = useState<firebase.User | null>();

    const [user, setUser] = useState<WithDocumentReferences<GlobalUserDetails>>();
    const [federations, setFederations] = useState<Record<string, UserSentral>>();

    useEffect(() => {
        const cancel = FAuth().onAuthStateChanged((authUser) => setAuthUser(authUser));

        return cancel;
    }, []);

    useEffect(() => {
        if (!authUser) {
            return;
        }
        FFirestore()
            .collection('users')
            .doc(authUser.uid)
            .get()
            .then((snapshot) => {
                const firebaseUser = snapshot.data() as GlobalUserDetails;

                setUser({
                    ...firebaseUser,
                    ref: snapshot.ref,
                });
            });
    }, [authUser]);

    useEffect(() => {
        if (!authUser || !user) {
            return;
        }
        if (!user.core_access.admin) {
            const listener = FFirestore()
                .collection('users')
                .doc(authUser.uid)
                .collection('sentral')
                .onSnapshot((snapshot) => {
                    const federations: Record<string, UserSentral> = {};
                    snapshot.docs.forEach((docSS) => {
                        const userSentral = docSS.data() as UserSentral;
                        federations[docSS.id] = userSentral;
                    });
                    setFederations(federations);
                });
            return () => {
                listener();
            };
        }
        FFirestore()
            .collection('sentral')
            .onSnapshot((snapshot) => {
                const federations: Record<string, UserSentral> = {};
                snapshot.docs.forEach((docSS) => {
                    federations[docSS.id] = {
                        forms: {},
                        menu: {},
                        permissions: {},
                        region: {},
                    };
                });
                setFederations(federations);
            });
    }, [authUser, user]);

    useEffect(() => {
        if (user) {
            updateLastLogin();
        }
    }, [user]);

    if (authUser === undefined) {
        return (
            <SPLoadingScreen>
                <SPFullLogoLoader />
            </SPLoadingScreen>
        );
    }

    if (authUser === null) {
        return <LoginScreen />;
    }

    if (user === undefined) {
        return (
            <SPLoadingScreen>
                <SPFullLogoLoader />
            </SPLoadingScreen>
        );
    }

    if (federations === undefined) {
        return (
            <SPLoadingScreen>
                <SPFullLogoLoader />
            </SPLoadingScreen>
        );
    }
    if (Object.keys(federations).length === 0) {
        return <LoginScreen />;
    }

    return (
        <AuthUserContext.Provider value={authUser}>
            <UserContext.Provider value={user}>
                <FederationsContext.Provider value={federations}>{children}</FederationsContext.Provider>
            </UserContext.Provider>
        </AuthUserContext.Provider>
    );
};

export const useUser = () => {
    const user = useContext(UserContext);

    if (!user) {
        throw new Error('No user available');
    }

    return user;
};

export const useAuthUser = () => {
    const authUser = useContext(AuthUserContext);

    if (!authUser) {
        throw new Error('No authUser available');
    }

    return authUser;
};

export const useFederations = () => {
    const federations = useContext(FederationsContext);

    if (!federations) {
        throw new Error('No federations available');
    }

    return federations;
};
