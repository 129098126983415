import * as config from './config/i18n';
import { BackendModule } from 'i18next';

const translationLoaderBackend: BackendModule = {
    type: 'backend',
    init: () => {},
    read: (language, namespace, callback) => {
        let field: string | null = null;
        let error: Error | null = null;

        try {
            const translations = config.supportedLocales[language].translationFileLoader().default as Record<
                string,
                any
            >;

            field = translations[namespace];
        } catch (_error) {
            if (_error instanceof Error) {
                error = _error;
            }
        }

        callback(error, field);
    },
};

export default translationLoaderBackend;
